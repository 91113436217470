<template>
  <div>
   <Slider />
    <Description section="saccos" />
    <Strengths />
    <Slogan />
    <TestimonialsPage />
    <Clients />
    <Partners />
    <!-- <Banner /> -->
  </div>
</template>

<script>
export default {
    name: "SMEs",
       components: {
      Slider: () => import('./sections/sliders/saccos'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/sacco-description'),

       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
      //  Banner: () => import('./sections/banner'),
       Partners: () => import('./sections/partners'),
       Clients: () => import('./sections/clients'),



    },
    computed: {
       strengths () {
          const data = [
              {
                    image: 'Pie_chart.png',
                    title: 'Mobile banking',
                    text: 'Designed to meet the dynamic client base of SACCOs, the PYCS’ mobile banking solution utilizes modern technology to deliver secure, efficient & convenient services by simplifying operations and delivering enhanced service to the customers. SACCO customers can use the solution for:',
                    color: '#d29f2a',
                },
                 {
                    image: 'Pie_chart.png',
                    title: 'Payments (Bank transfers & Mobile money)',
                    text: 'Use Zamupay for your payments, financial analytics, reporting, reconciliation, tracking and auditing of your transactions.',
                    color: '#d29f2a',
                },
              
         ];
         return data;
       }

    },

}
</script>

<style>

</style>